import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { transferHasLooseProducts, transferOptionSelectedAtom, transferOriginInfoAtom } from '../transfer.state'
import { useGoToMoveOptionsNavigator } from '../move-options/MoveOptions.navigator'
import { useNavigate } from 'react-router-dom'
import { useScanner } from 'hooks/scanner.hook'
import { TRANSFER_PRODUCT_LIST } from '../move-product-list/MoveInventoryProductList.navigator'
import { getContainerFromScan } from 'client/scanner/scanner.client'
import { manageError } from 'utils/errors/error.handler'
import { useTranslation } from 'react-i18next'
import { type ContainerResponse } from '../../../client/location/location.types'
import { getContainersInLocation } from '../../../client/location/location.client'

export const useContainers = (onError: (str: string) => void) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [containers, setContainers] = useState<ContainerResponse[]>([])

  const [origin, setOrigin] = useAtom(transferOriginInfoAtom)
  const [hasLooseProducts] = useAtom(transferHasLooseProducts)
  const [, setOption] = useAtom(transferOptionSelectedAtom)

  const moveOptions = useGoToMoveOptionsNavigator()

  const navigate = useNavigate()
  const { t } = useTranslation('global')

  const {
    keys,
    getScan
  } = useScanner()

  /* Scan container in this page */
  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      setIsLoading(true)
      getContainerFromScan(scanned)
        .then((scan) => {
          if (!containers.find(it => it.name === scan.container.name)) {
            throw Error(t('traslation.choose-container.not-found'))
          }
          return scan
        })
        .then(origin => {
          setOrigin(origin)
          moveOptions.go(origin)
        })
        .catch(manageError(`ScanOrigin.hooks.ts#useEffect ${scanned}`))
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [keys])

  /* Get containers from origin */
  useEffect(() => {
    if (origin != null) {
      setIsLoading(true)
      getContainersInLocation(origin.location.name)
        .then(setContainers)
        .catch(e => {
          console.error(e)
          onError(e.message as string)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [])

  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    origin: origin!,
    containers,
    isLoadingPage: isLoading,
    hasLooseProducts,
    actions: {
      back: () => {
        navigate(-1)
      },
      goToMoveProducts: () => {
        // TODO: pending to resolve this
        setOption('move-loose-products')
        navigate((TRANSFER_PRODUCT_LIST + '?looseProducts=true').replace(':origin', origin?.location.legacyId!).replace(':container', origin?.container?.legacyId!))
      }
    }
  }
}
