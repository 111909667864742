import { UserManagment, type LoggedUser } from '@justomx/webview-commons'
import { isProduction } from '../utils/environment'

export interface CommonHeaders {
  'x-hardcoded': boolean
  authorization: string
  'x-authorization-wms': string
  'x-authorization-core': string
  'x-justo-country': string
  'x-justo-warehouse'?: string
}

const checkJWTIssuer = (authorization: string) => {
  const [_header, payload, _signature] = authorization.split('.')
  const body = JSON.parse(atob(payload));
  return body.iss?.startsWith('https://auth.justo.cloud/realms/operations-') ||
    body.iss?.startsWith('https://auth.dev.justo.cloud/realms/operations-');
}

export const getUser = () => {
  let user: LoggedUser | undefined
  try {
    user = UserManagment.getCurrentUser()
  } catch {
    user = undefined
  }
  return user
}

export const getCommonHeaders = (): CommonHeaders => {
  const user = getUser()
  const metadata = (window as any)?.WVMetadata

  if (user) {
    const isProperIssuer = checkJWTIssuer(user.authorizationWms);

    if (!isProperIssuer) {
      throw new Error('Autorización inválida (estás intentando acceder desde Atlas-wms?)');
    }

    return {
      'x-hardcoded': false,
      authorization: `Bearer ${user.authorizationWms}`,
      'x-authorization-wms': `Bearer ${user.authorizationWms}`,
      'x-authorization-core': `Bearer ${user.authorizationCore}`,
      'x-justo-country': user.country?.toUpperCase(),
      'x-justo-warehouse': user.warehouse?.toUpperCase()
    }
  } else if (metadata) {
    const err = new Error('Autorización inválida (estás intentando acceder desde Atlas-wms?).');
    throw err;
  }

  throw new Error('El usuario no está autenticado.');
}

export function getCurrentCountry () {
  const user = getUser()
  if (user) {
    return user.country?.toUpperCase()
  }

  if (isProduction()) {
    console.log('Metadata of WV is undefined, using default values.')
  }
  return 'MX'
}

export function getCurrentWarehouse () {
  const user = getUser()

  if (user) {
    return user.warehouse?.toUpperCase()
  }

  if (isProduction()) {
    console.log('Metadata of WV is undefined, using default values.')
  }
  return 'PP'
}

export function getCurrentUserId () {
  const { authorization } = getCommonHeaders()
  const [_header, payload, _signature] = authorization.split('.')
  const body = JSON.parse(atob(payload))
  return body.id.toString()
}
