import { InventoryProduct, type ProductInfo } from '../../types/model'
import { productGatewayAxiosInstance } from '../../utils/op-product-gateway.axios.instance'
import { type ProductDetailsResponse } from './products.types'
import { axiosInstance } from '../../utils/axios.instance'
import { ProductResponse } from 'client/inventory/inventory.types'

export const findProductInfo = async (ean: string): Promise<ProductInfo> => {
  return await productGatewayAxiosInstance().get<ProductInfo>(`/v1/products/${ean}`)
    .then((res) => res.data)
}

export const findProductsInfo = async (eans: string[]): Promise<ProductInfo[]> => {
  return await productGatewayAxiosInstance()
    .get<ProductInfo[]>('/v1/products/multiple/eans', { params: { n: eans.join(',') } })
    .then((res) => res.data)
}

// TODO: move to productGatewayAxiosInstance
export const getProductDetailFromEan = async (ean: string) => {
  const axios = axiosInstance()
  return await axios.get<ProductDetailsResponse>(`/products/${ean}/details`)
    .then(e => e.data)
}

export const mapProductsWithInfo = async (products: ProductResponse[]) => {
  const productsWithEan = await findProductsInfo(products.map(p => p.productEan))
  return products.map<InventoryProduct>((p) => {
    const product = productsWithEan.find(it => it.ean === p.productEan)
    return {
      productId: product?.id ?? 'NULL',
      productEan: product?.ean ?? p.productEan,
      productSku: product?.sku ?? p.productSku,
      productName: product?.name ?? 'PRODUCT UNKNOWN',
      productImage: product?.image ?? 'https://media.justo.mx/fallback.jpg',
      byWeight: product?.isWeighable ?? false,
      stock: p.stock,
      country: p.country,
      warehouse: p.warehouse
    }
  })
}
