import { AtlasColor, CardComponent, CardRow, StackAtom, Divider, AtlasBodyMain} from "@justomx/atlas-components";
import ProductInfo from "./product-info.component";
import {  ReverseLogisticProductInfo } from "types/model";
import { useTranslation } from "react-i18next";

type ProductCardProps = {
  product: ReverseLogisticProductInfo
  showStock?: boolean
  showPriority?: boolean
  showWasteIcon?: boolean
  showTitle?: boolean
  openDialog?: () => void
}

export function ProductCard({product, showStock, showPriority, showWasteIcon, showTitle, openDialog}: ProductCardProps) {
  const {t} = useTranslation('global')
  return <CardComponent>
    {showTitle && <AtlasBodyMain style={{ color: AtlasColor.Neutral20 }}>{t("reverse-logistics.scanned-product")}</AtlasBodyMain>}
    <StackAtom style={{flexDirection: 'column', alignItems: 'baseline'}} gap={12}>
      <ProductInfo product={product} showPriority={showPriority} showWasteIcon={showWasteIcon} openDialog={openDialog}/>
      {showStock &&
        <> 
        <Divider/>
        <CardRow style={{ width: '100%', justifyContent: 'space-between'}}>
          <AtlasBodyMain color={AtlasColor.Neutral40}>{product.isSampling ? <span style={{backgroundColor: '#474747', padding: '4px 8px', borderRadius: '4px', color: '#FFFFFF'}}>Sampling</span> : t('universal-search.quantity')}</AtlasBodyMain>
          <AtlasBodyMain>{product.pendingQuantity} {product.isWeighable ? t('uom.grams') : t('uom.units')}</AtlasBodyMain>
        </CardRow>
        </>
      }
    </StackAtom>
  </CardComponent>;
}

