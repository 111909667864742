import { useScanner } from '../../../../hooks/scanner.hook'
import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { useReverseLogisticsRegister } from '../register-product-quantity/RegisterProductQuantity.navigator'
import { reverseLogisticsSelectedProductAtom, reverseLogisticsScannedSuppliesReader } from '../reverse-logistics.state'
import { getProductFromScan } from 'client/scanner/scanner.client'
import { ReverseLogisticProductInfo, TareOrProduct } from 'types/model'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { useReverseLogisticsRegisterWasteOrMissing } from '../register-waste-or-missing/RegisterWasteOrMissing.navigator'
import { useReverseLogisticsNavigator } from '../ReverseLogistics.navigator'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export const useScanProduct = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const navigate = useNavigate()
  const registerNavigator = useReverseLogisticsRegister()
  const [selectedProduct, setSelectedProduct] = useAtom(reverseLogisticsSelectedProductAtom) 
  const [scannedSupplies] = useAtom(reverseLogisticsScannedSuppliesReader)
  const [loading, setLoading] = useState(false)
  const {
    dialogRef,
    open: openWasteOrMissingDialog,
  } = useConfirmationDialog()
  const {
    dialogRef: dialogRefSampling,
    open: openSamplingDialog,
  } = useConfirmationDialog()
  const {
    dialogRef: typeProductDialogRef,
    open: openTypeProductDialog,
  } = useConfirmationDialog()
  const { go } = useReverseLogisticsRegisterWasteOrMissing()
  const reverseLogistics = useReverseLogisticsNavigator()
  const { t } = useTranslation('global')
  const [productsGroupedByEan, setProductsGroupedByEan] = useState<{ [ean: string]: ReverseLogisticProductInfo }>({})

  const searchProduct = async (ean: string) => {
    getProductFromScan(ean)
      .then(e => {
        const matchingKeys = Object.keys(productsGroupedByEan).filter((key) => key.startsWith(`${ean}-`));
        if (matchingKeys.length === 1) {
          const productKey = matchingKeys[0];
          const scannedProduct = productsGroupedByEan[productKey];
          setSelectedProduct({ ...scannedProduct, id: e.product.legacyId } as ReverseLogisticProductInfo);
          registerNavigator.go(ean);
        } else if (matchingKeys.length === 2) {
          setSelectedProduct(productsGroupedByEan[`${ean}-true`]);
          openSamplingDialog();
        } else {
          toast.error(t('reverse-logistics.scan-product.error-not-found'));
        }
      })
      .catch(e => {
        console.error(e)
        toast.error('Error al obtener producto: ' + ean)
      })
  }

  const groupProductsByEan = (tareOrProducts: TareOrProduct[]) => {
    const productMap: { [key: string]: ReverseLogisticProductInfo } = {};
  
    tareOrProducts.forEach((item) => {
      item.products
        .filter((product) => product.status !== "Completed")
        .forEach((product) => {
          const key = `${product.ean}-${product.isSampling}`
  
          if (productMap[key]) {
            productMap[key].pendingQuantity += product.pendingQuantity;
          } else {
            productMap[key] = { ...product };
          }
        });
    });
    setProductsGroupedByEan(productMap);
  }
  
  const groupProductsByZone = (productMap: { [ean: string]: ReverseLogisticProductInfo }): { [zone: string]: ReverseLogisticProductInfo[] } => {
    const groupedByZone: { [zone: string]: ReverseLogisticProductInfo[] } = {};
  
    // Group products by zone
    Object.values(productMap).forEach((product) => {
      if (groupedByZone[product.zone]) {
        groupedByZone[product.zone].push(product);
      } else {
        groupedByZone[product.zone] = [product];
      }
    });
  
    // Create an array of zones and sort it by the priority of products in each zone
    const sortedZones = Object.entries(groupedByZone).sort(([, productsA], [, productsB]) => {
      return productsA[0].priority - productsB[0].priority;
    });
  
    // Convert the sorted array of zones back into an object
    const sortedGroupedByZone = sortedZones.reduce((acc, [zone, products]) => {
      acc[zone] = products;
      return acc;
    }, {} as { [zone: string]: ReverseLogisticProductInfo[] });
  
    return sortedGroupedByZone;
  };
  

  useEffect(() => {
    groupProductsByEan(scannedSupplies)
  }, [scannedSupplies])
  
  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      searchProduct(scanned)
    }
  }, [keys])

  return {
    products: groupProductsByZone(productsGroupedByEan),
    loading,
    dialogRef,
    dialogRefSampling,
    typeProductDialogRef,
    selectedProduct,
    actions: {
      searchProduct,
      openWasteOrMissingDialog: (product) => {
        setSelectedProduct(product)
        openWasteOrMissingDialog()
      },
      openTypeProductDialog,
      goBack: () => {
        navigate(-1)
      },
      goToWasteOrMissingPage: go,
      goHome: () => {
        reverseLogistics.go()
      },
      goToRegister: (isSampling, product) => {
        setSelectedProduct(productsGroupedByEan[`${product.ean}-${isSampling}`])
        registerNavigator.go(product.ean)
      }
    }
  }
}
