import { ProductResponse } from 'client/inventory/inventory.types'
import type { ProductInfo } from 'types/model'

export function validateProduct (product: ProductInfo, inventoryProducts: ProductResponse[]) {
  const selectedProduct = inventoryProducts.find((p) => p.productEan === product.ean)

  if (selectedProduct === undefined) {
    throw new Error('El producto no está registrado en la ubicación, actualiza la información.')
  }

  if (selectedProduct?.stock === 0) {
    throw new Error('El producto no tiene stock en la ubicación o contenedor seleccionado.')
  }

  return {
    ...selectedProduct,
    productZone: product.zone,
    byWeight: product.isWeighable,
    productImage: product.image,
    productName: product.name,
    productSku: product.sku,
  }
}
