import { useScanner } from '../../../hooks/scanner.hook'
import { useEffect } from 'react'
import { manageError } from '../../../utils/errors/error.handler'
import { useAtom } from 'jotai'
import { inventoryWasteProductsReader, selectedProductAtom } from '../inventory-waste.state'
import { useInventoryWasteRegisterNavigator } from '../register-waste/RegisterWaste.navigator'
import { useNavigate } from 'react-router-dom'
import { validateProduct } from './ScanProduct.validator'
import { findProductInfo } from 'client/products/products.client'

export const useScanProduct = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const registerWaste = useInventoryWasteRegisterNavigator()
  const navigate = useNavigate()
  const [, setProduct] = useAtom(selectedProductAtom)
  const [inventoryProducts] = useAtom(inventoryWasteProductsReader)

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      findProductInfo(scanned)
        .then((product) => validateProduct(product, inventoryProducts))
        .then((product) => {
          setProduct(product)
          registerWaste.go(scanned)
        })
        .catch(manageError('Producto no encontrado'))
    }
  }, [keys])

  return {
    actions: {
      goBack: () => {
        navigate(-1)
      }
    }
  }
}
