import { useGoToScanProductInContainer } from '../scan-product-in-container/ScanProductInContainer.navigator'
import { type UseHasInventoryProducts, useInventoryProducts } from '../../../hooks/inventoryProducts.hooks'
import { useLoaderData } from 'react-router-dom'
import { useGoToInventoryProductFix } from '../product-fix/InventoryProductFix.navigator'
import { isErrorResponse } from '../../../internal-types/HasError'
import { useGoToScanContainerLocation } from '../scan-container-location/ScanContainerLocation.navigator'
import { useCurrentLocationAndContainer } from '../../../hooks/currentLocation.hook'
import { ScannedContainer, ScannedLocation, type ScanResponse } from 'client/scanner/scanner.types'
import { useEffect, useState } from 'react'
import { getProducts } from 'client/inventory/inventory.client'
import { mapProductsWithInfo } from 'client/products/products.client'
import { useAtom } from 'jotai'
import { inventoryFixOriginInfoReader, inventoryFixSelectedProductAtom, inventoryProductsAtom } from '../InventoryFix.state'
import { InventoryProduct } from 'types/model'
import { handleError } from 'pages/receipt/client/Receipt.error'

interface InventoryProductListHookResponse {
  origin: ScannedLocation | ScannedContainer
  filteredProducts: InventoryProduct[]
  isLoading: boolean
  actions: {
    goToScanProduct: () => void
    goToInventoryProductFix: (ean: string) => void
    back: () => void
    handleFilterChange: (value: string) => void
    setSelectedProduct: (product: InventoryProduct) => void
  }
}

export const useInventoryProductsList = (): InventoryProductListHookResponse => {
  const scanProduct = useGoToScanProductInContainer()
  const inventoryProductFix = useGoToInventoryProductFix()
  const scanContainerLocation = useGoToScanContainerLocation()

  const [origin] = useAtom(inventoryFixOriginInfoReader)
  const [products, setProducts] = useAtom(inventoryProductsAtom)
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [, setSelectedProduct] = useAtom(inventoryFixSelectedProductAtom)

  const [isLoading, setIsLoading] = useState(false)

  /* Getting products */
  useEffect(() => {
    setIsLoading(true)
    getProducts(origin.container?.name ?? origin.location.name)
      .then(async (products) => {
        if (products.length === 0) {
          return []
        }
        return await mapProductsWithInfo(products)
      })
      .then((products) => {
        setProducts(products)
        setFilteredProducts(products)
      })
      .catch((err) => {
        handleError((err as Error).message ?? 'Error getting products')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])


  const handleFilterChange = (value: string) => {
    if (!value.trim()) {
      setFilteredProducts(products);
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = products.filter((product) =>
        product.productEan.toLowerCase().includes(lowercasedValue) ||
        product.productSku.toLowerCase().includes(lowercasedValue) ||
        product.productName.toLowerCase().includes(lowercasedValue)
      );
      setFilteredProducts(filtered);
    }
  };

  return {
    origin,
    filteredProducts,
    isLoading,
    actions: {
      goToScanProduct: () => {
        scanProduct.go({
          origin
        })
      },
      goToInventoryProductFix: (ean: string) => {
        inventoryProductFix
          .go({
            origin,
            ean
          })
      },
      back: () => {
        scanContainerLocation.go()
      },
      handleFilterChange,
      setSelectedProduct
    }
  }
}
