import { atomWithStorage } from 'jotai/utils'
import { type InventoryWasteReport, type LocationAndContainer } from '../../types/model'
import { createReader } from '../../utils/atomReader'
import { type ScanResponse } from '../../client/scanner/scanner.types'
import { ProductResponse } from 'client/inventory/inventory.types'

export const inventoryWasteOriginInfoAtom = atomWithStorage<ScanResponse | null>('inventoryWasteOriginInfo', null)
export const inventoryWasteOriginInfoReader = createReader(inventoryWasteOriginInfoAtom)

export const inventoryWasteDestinationInfoAtom = atomWithStorage<LocationAndContainer | null>('inventoryWasteDestinationLocationInfo', null)
export const traslationDestinationInfoReader = createReader(inventoryWasteDestinationInfoAtom)

export const selectedProductAtom = atomWithStorage<ProductResponse | null>('selectedProduct', null)
export const selectedProductReader = createReader(selectedProductAtom)

export const inventoryWasteReportAtom = atomWithStorage<InventoryWasteReport | null>('inventoryWasteReport', null)
export const inventoryWasteReportReader = createReader(inventoryWasteReportAtom)

export const inventoryWasteProductsAtom = atomWithStorage<ProductResponse[] | null>('inventoryWasteProducts', null)
export const inventoryWasteProductsReader = createReader(inventoryWasteProductsAtom)
